import React from 'react'
import { graphql, Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Modal from '../components/Modal'
// import GetStartedForm from '../components/Form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import kebabCase from "lodash/kebabCase"
import './headless-cms-sandbox.css'
import './seo.css'

if (typeof window !== `undefined`) {
var CanvasJS = require('../../static/js/canvasjs.min.js');
}



// var CanvasJSReact = require('../../static/js/canvasjs.react');
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class seoPage extends React.Component {
   constructor(props, context) {
    super(props, context);
    this.handleRemove = this.handleRemove.bind(this);

  }

  handleRemove() {
    let element = document.getElementById('gvam');
    element.parentNode.removeChild(this);

  }


  componentDidMount() {
      if(window) {
              // function onAddScript(e) {
              //   const script=window.document.createElement('script')
              //   script.id="gvam"
              //   script.src="https://gvam.maillist-manage.com/js/optin.min.js"
              //   script.setAttribute('onload',"setupSF('sf8114c0ed5ea59b2227b958a37b88af3fab16e7ccaa8dd327','ZCFORMVIEW',false,'light')")
              //   window.document.head.append(script);
                  
              // }
              // let getStarted = document.getElementsByClassName('btn-getstarted');
              // for (var i = 0; i < getStarted.length; i++) {
              //   getStarted[i].addEventListener('click', onAddScript)
              // }


              // let getStarted = document.getElementsByClassName('btn-getstarted');
              // getStarted[0].addEventListener('click', function(e) {
              //     const script=window.document.createElement('script')
              //     script.id="gvam"
              //     script.src="https://gvam.maillist-manage.com/js/optin.min.js"
              //     script.setAttribute('onload',"setupSF('sf8114c0ed5ea59b2227b958a37b88af3fab16e7ccaa8dd327','ZCFORMVIEW',false,'light')")
              //     window.document.head.append(script);

              // })
              

              CanvasJS.addColorSet("googleShades",["#3480d3","#ffc001","#264478","#00b0f0","#dc3545","#7031a0","#92cf51","#ff5051"])
              var localchart = new CanvasJS.Chart("localSeo", {
                  colorSet: "googleShades",
                  theme: "light1",
                  backgroundColor: '#f8f9fa',
                  animationEnabled: true,
                  legend: {
                      maxWidth: 350,
                      itemWidth: 120,
                      fontFamily: "Open Sans",
                  },
                  data: [{
                      type: "pie",
                      startAngle: 25,
                      toolTipContent: "<b>{label}</b>: {y}%",
                      indexLabelFontFamily: "Open Sans",
                      indexLabelFontWeight: "600",
                      indexLabel: "{label} - {y}%",
                      dataPoints: [
                          { y: 16, label: "Original Content" },
                          { y: 11, label: "Citations" },
                          { y: 3, label: "Social" },
                          { y: 14, label: "On-page" },
                          { y: 15, label: "Reviews" },
                          { y: 16, label: "Links" },
                          { y: 25, label: "Google My Business" }
                      ]
                  }]
              });
              CanvasJS.addColorSet("bootstrapShades",
              [
                  "#0658ff",
                  "#6f42c1",
                  "#fd7e14",
                  "#20c997",
                  "#e83e8c",
                  "#ffc107"
              ]);
              var regionalchart = new CanvasJS.Chart("regionalSeo", {
                  colorSet: "bootstrapShades",
                  theme: "light1",
                  backgroundColor: '#eef1f8',
                  animationEnabled: true,
                  legend: {
                      maxWidth: 350,
                      itemWidth: 120,
                      fontFamily: "Open Sans",
                  },
                  data: [{
                      type: "pie",
                      startAngle: 25,
                      toolTipContent: "<b>{label}</b>: {y}%",
                      indexLabelFontFamily: "Open Sans",
                      indexLabelFontWeight: "600",
                      indexLabel: "{label} - {y}%",
                      dataPoints: [
                          { y: 44.5, label: "Website Content & Behavior" },
                          { y: 28, label: "Links Coming Into Your Site" },
                          { y: 8.5, label: "Consistent Listing Information" },
                          { y: 9, label: "Google My Business Listing" },
                          { y: 6.5, label: "Reviews" },
                          { y: 3.5, label: "Social Media" }
                      ]
                  }]
              });
              localchart.render();
              regionalchart.render();


      }
  }

render() {
  const { data } = this.props
  const siteTitle = "SCALE YOUR BRAND with SEO-focused content marketing"
  const siteDescription = this.props.data.site.siteMetadata.description

  const posts = data.allposts.edges
  
  return (
    <Layout location={this.props.location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDescription} />
      <div className="headless-cms-sandbox">
      <section id="digital" className="component py-0">

          <Container>
            <Row className="align-items-center row justify-content-center">
              <Col md={6} className="ml-lg-auto">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                  <Col xs="auto">
                    <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-1.png" alt="3"/>
                  </Col>
                  <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                    <h5 className="font-weight-normal my-3">Search Engine Optimization (SEO)</h5>
                    <p>We will place your message in front of the right searches to help you gain more clicks, more conversions, and more customers.</p>
                  </Col>
                </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage className="img-fluid" effect="blur" src="/img/008-sms.svg" alt="seo" width="280" height="280"/>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
      <section id="hub" className="component">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5} className="text-center mb-4 mb-lg-0 order-2 order-md-1">
              <LazyLoadImage className="img-fluid" effect="blur" src="/img/007-seo.svg" alt="local seo" width="280" height="280"/>
            </Col>
            <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
              <div className="content">
                <Row className="align-items-start justify-content-start">
                  <Col xs="auto">
                    <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-2.png" alt="2"/>
                  </Col>
                  <Col xs={`${8} pl-0 pl-sm-3`} lg={6} xl={7}>
                    <h5 className="font-weight-normal my-3">Local SEO</h5>
                    <p>It’s ideal to be in the top 3 on maps, claim your Google business listing, build reviews, citations, and local links. Make sure your site is beyond mobile-friendly.</p>
                  </Col>
                </Row>

              </div>

            </Col>
          </Row>
        </Container>
      </section>
      <section id="value-content" className="component">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={6} xl={7}  className="ml-lg-auto mb-4 mb-lg-0">
              <div className="content">
                
                <Row className="align-items-center justify-content-start">
                  <Col xs="auto">
                    <LazyLoadImage className="img-fluid mb-0" effect="blur" src="/img/offer-3.png" alt="3"/>
                  </Col>
                  <Col xs={`${8} pl-0 pl-sm-3`} sm={6}>
                    <h5 className="font-weight-normal my-3">Content Marketing is SEO!!</h5>
                    <p>We manage blogs, web copy, product reviews, newsletters, and social media posts. Your content will get read and will convert into actual leads.</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className="text-center text-md-left">
                <LazyLoadImage className="img-fluid" effect="blur" src="/img/001-mobile-app.svg" alt="content marketing" width="280" height="280"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

       <section className="component bg-blue text-white">
          <Container>
              <div className="intro text-center mb-5"><h3 className="font-weight-normal text-white mt-0">Website as a Resource Center</h3></div>
              <Row>
                  <Col md={4}>
                      <div className="content text-center">
                          <LazyLoadImage
                            className="img-fluid"
                            effect="blur"
                            src="/img/002-updated.svg"
                            alt="crm-more-than-crm"
                            width="80"
                            height="80"
                          />
                          <h5 className="font-weight-normal mt-0 mb-3">Continuous<br/> website updates</h5>
                          <p>Content output and marketing will transform your website into a living, breathing resource center for prospective and current clients</p>
                      </div>
                  </Col>
                  <Col md={4}>
                      <div className="content text-center">
                      <LazyLoadImage
                            className="img-fluid"
                            effect="blur"
                            src="/img/003-modules.svg"
                            alt="crm-more-than-crm"
                            width="80"
                            height="80"
                          />
                           <h5 className="font-weight-normal mt-0 mb-3">Original<br/> content</h5>
                    <p>As part of an inbound marketing plan, original content is created and marketed only for your firm.</p>

                      </div>
                  </Col>
                  <Col md={4}>
                      <div className="content text-center">
                      <LazyLoadImage
                            className="img-fluid"
                            effect="blur"
                            src="/img/001-megaphone.svg"
                            alt="crm-more-than-crm"
                            width="80"
                            height="80"
                          />
                      <h5 className="font-weight-normal mt-0 mb-3">
                      Quality content for your<br/> target market
                    </h5>
                    <p>Whether it’s through your blog, email newsletter, blog exchanges, podcasts, article directories, wire distributions, social media and more. Your content is there when a potential client needs it.</p>
                      </div>
                  </Col>
                  
              </Row>
              <div className="d-flex justify-content-center pt-0 my-3">

            <Modal size="md" btnclass="hvr-shadow mb-0 btn btn-primary btn-orange btn-getstarted" html="Get Started">
              <div className="px-5 py-4 bg-primary">
                <h3 className="text-white m-0">Get Started</h3>
              </div>
              <iframe
                    src="https://gvam.maillist-manage.com/ua/Optin?od=11287ecb0744d9&zx=127fa67d7&lD=18398065a394f3a1&n=11699f7514e4312&sD=18398065a3951ea1"
                    width="100%"
                    frameborder="0"
                    height="645px"
                    title="New WGM CTA"
                 />
          </Modal>
          </div>
          </Container>
        </section>
      <section id="mozlow" className="component">
          <div className="intro text-center mb-4"><h3 className="font-weight-normal text-blue mt-0">Mozlow's Hierarchy of SEO Needs</h3></div>
          <Container>
          <a href="https://moz.com/beginners-guide-to-seo" target="_blank" rel="noopener noreferrer">
            <LazyLoadImage className="img-fluid" effect="blur" src="/img/SEO-stuff.jpg"/>
          </a>
          </Container>

      </section>

      <section id="pie-chart-local" className="component bg-light">
          <div className="intro text-center mb-4"><h3 className="font-weight-normal text-blue mt-0">How Local SEO works</h3></div>
          <Container>
              <div id="localSeo" style={{height: '370px', width: '100%'}}></div>
          </Container>

      </section>
      <section id="pie-chart-regional" className="component bg-light-purple">
          <div className="intro text-center mb-4"><h3 className="font-weight-normal text-blue mt-0">How Regional and National SEO works</h3></div>
          <Container>
              <div id="regionalSeo" style={{height: '370px', width: '100%'}}></div>
          </Container>
          

      </section>
      <section id="seo-blog-category" className="component">
          <Container>
              <Row>
                  {posts.map(({node}) => {
                      const cloudinary = `https://res.cloudinary.com/dnla85pdq/image/upload/w_320,h_200,c_thumb/v1540269241/webriq/images/${node.frontmatter.banner}`
                      return (
                          <Col key={node.id} md={4}>
                              <div className="article-list-banner mb-2" 
                                style={{ 
                                  backgroundImage: `url(${cloudinary})`,
                                  backgroundPosition: `center center`,
                                  backgroundSize:`cover`,
                                  height: `200px`
                              
                              }}/>
                              <h5 className="mt-0"><Link className="article-list-link" to={node.fields.slug}>{node.frontmatter.title}</Link></h5>
                              <p>{node.excerpt}</p>
                              <p><span className="text-secondary">By</span> <Link className="text-black" to={`/author/${kebabCase(node.frontmatter.author)}/`}>{node.frontmatter.author}</Link> <span className="text-secondary">in</span> <span className="text-blue text-capitalize"><Link to={`/category/${kebabCase(node.frontmatter.categories)}/`}>{node.frontmatter.categories}</Link></span> <span className="text-secondary">{node.frontmatter.date}</span></p>
                          </Col>

                      )
                      
                  })}
              </Row>
              <div className="d-flex justify-content-center pt-0 mt-4">

            <Modal size="md" onHide={this.handleRemove} btnclass="hvr-shadow mb-0 btn btn-primary btn-getstarted" html="Get Started">
              <div className="px-5 py-4 bg-primary">
                <h3 className="text-white m-0">Get Started</h3>
              </div>
              <iframe
                    src="https://gvam.maillist-manage.com/ua/Optin?od=11287ecb0744d9&zx=127fa67d7&lD=18398065a394f3a1&n=11699f7514e4312&sD=18398065a3951ea1"
                    width="100%"
                    frameborder="0"
                    height="645px"
                    title="New WGM CTA"
                 />
          </Modal>
          </div>
          </Container>
      </section>
      
     
</div>
    </Layout>
  )
}
}

export default seoPage

export const seoPageQuery = graphql`
query seoPageQuery {
  site {
    siteMetadata {
      title
      author
      description
    }
  }
  allposts: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(posts)/"}, frontmatter: {categories: {eq: "SEO"}}}, 
      sort: {fields: [frontmatter___date], order: DESC},
      limit:3
  ) {
    edges {
      node {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          banner
          author
          categories
          featured
        }
      }
    }
    group(field: frontmatter___categories) {
      fieldValue
      totalCount
    }
  }
}
`